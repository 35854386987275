import { gql } from '@apollo/client';

export const CLIENT_FRAGMENT = gql`
  fragment ClientFragment on Client {
    type
    subscribed
    birthDate
    profession
    occupation
    gender
    addresses {
      _id
      firstLine
      secondLine
    }
    active
    _id
    createdAt
    updatedAt
  }
`;

export const LAWYER_FRAGMENT = gql`
  fragment LawyerFragment on Lawyer {
    firm {
      _id
      name
      active
      abbreviation
    }
    active
    _id
    createdAt
    updatedAt
    inpre
    signature
    calendlyKey
    private
    enterprises {
      _id
      name
      active
      sector
      createdAt
      updatedAt
    }
  }
`;

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    onboardingCompleted
    jobPosition
    onboardingCompleted
    enterprise {
      _id
      name
    }
    slug
    email
    calendlyId
    password
    firstName
    lastName
    permissions {
      _id
      name
      key
      active
      option
    }
    resetTokenValidity
    resetToken
    dni
    dniType
    phones
    profilePicture
    active
    _id
    role
    createdAt
    updatedAt
    lawyer {
      private
      enterprises {
        _id
        name
        active
        sector
        createdAt
        updatedAt
      }
      calendlyKey
      active
      _id
      createdAt
      updatedAt
      inpre
      signature
      firm {
        _id
        name
        active
        abbreviation
        country {
          _id
          name
          abbreviation
          currency {
            _id
            name
            rate
            active
            symbol
          }
          paymentMethods {
            _id
            name
            active
            commission
            currency {
              _id
              name
              symbol
              rate
              active
            }
          }
          currency {
            _id
            name
            symbol
            rate
          }
        }
      }
    }
    client {
      type
      subscribed
      birthDate
      profession
      occupation
      gender
      addresses {
        _id
        firstLine
        secondLine
      }
      active
      _id
      createdAt
      updatedAt
    }
  }
`;

export const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on Category {
    _id
    name
    parent {
      _id
      name
    }
    active
    children {
      _id
      name
      parent {
        _id
        name
      }
      active
      children {
        _id
        name
        parent {
          _id
          name
        }
        active
        children {
          _id
          name
          parent {
            _id
            name
          }
          active
          children {
            _id
            name
            parent {
              _id
              name
            }
            active
            children {
              _id
              name
              parent {
                _id
                name
              }
              active
              children {
                _id
                name
                parent {
                  _id
                  name
                }
                active
              }
            }
          }
        }
      }
    }
  }
`;
export const PERMISSION_FRAGMENT = gql`
  fragment PermissionFragment on Permission {
    _id
    name
    key
    option
    active
  }
`;

export const TAX_FRAGMENT = gql`
  fragment TaxFragment on Tax {
    _id
    name
    modifier
    type
    active
    createdAt
    updatedAt
  }
`;

export const PLAN_FRAGMENT = gql`
  fragment PlanFragment on Plan {
    name
    price
    duration
    durationUnit
    unlimitedDocuments
    unlimitedDocumentsSent
    documentsLimit
    documentsSentLimit
    isFreeTrial
    taxes {
      ...TaxFragment
    }
    active
    _id
    createdAt
    updatedAt
  }
  ${TAX_FRAGMENT}
`;

export const COUNTRY_FRAGMENT = gql`
  fragment CountryFragment on Country {
    name
    abbreviation
    currency {
      _id
      name
      symbol
      rate
      active
    }
    paymentMethods {
      _id
      name
      commission
      currency {
        _id
        name
        symbol
        rate
        active
      }
    }
    contactDetails {
      _id
      type
      value
    }
    active
    _id
    createdAt
    updatedAt
  }
`;

export const CURRENCY_FRAGMENT = gql`
  fragment CurrencyFragment on Currency {
    _id
    name
    symbol
    rate
    active
  }
`;

export const PROMOTION_FRAGMENT = gql`
  fragment PromotionFragment on Promotion {
    _id
    name
    type
    modifier
    code
    createdAt
    updatedAt
    active
    priceEffect
  }
`;

export const ENTERPRISE_FRAGMENT = gql`
  fragment EnterpriseFragment on Enterprise {
    _id
    name
    image
    country {
      ...CountryFragment
    }
    sector
    active
    createdAt
    updatedAt
    promotion {
      ...PromotionFragment
    }
  }
  ${COUNTRY_FRAGMENT}
  ${PROMOTION_FRAGMENT}
`;

export const SUBSCRIPTION_PAYMENT_FRAGMENT = gql`
  fragment SubscriptionPaymentFragment on SubscriptionPayment {
    plan {
      ...PlanFragment
    }
    amountPaid
    currency {
      ...CurrencyFragment
    }
    enterprise {
      ...EnterpriseFragment
    }
    endDate
    stripeSubscriptionId
    status
    active
    _id
    createdAt
    updatedAt
  }
  ${PLAN_FRAGMENT}
  ${CURRENCY_FRAGMENT}
  ${ENTERPRISE_FRAGMENT}
`;

export const PAYMENT_METHOD_FRAGMENT = gql`
  fragment PaymentMethodFragment on PaymentMethod {
    _id
    name
    currency {
      _id
      name
      symbol
      rate
      active
    }
    commission
    details {
      name
      value
    }
    active
    createdAt
    updatedAt
  }
`;

export const FIRM_FRAGMENT = gql`
  fragment FirmFragment on Firm {
    name
    email
    rif
    phones
    photos
    country {
      name
      _id
    }
    abbreviation
    active
    _id
    createdAt
    updatedAt
  }
`;

export const DOCUMENT_FRAGMENT = gql`
  fragment DocumentFragment on Document {
    _id
    name
    priority
    docType
    requiresVisa
    category {
      _id
      name
    }
    taxes {
      _id
      name
      modifier
      type
      active
      createdAt
      updatedAt
    }
    requiresFormalization
    autoApproval
    user {
      ...UserFragment
    }
    price
    legalFee
    description
    requirements
    questionnaires {
      _id
      title
      description
      questions {
        _id
        name
        tagName
        question
        type
        options
        placeholder
      }
      active
      country {
        _id
        name
      }
    }
    template
    history {
      _id
      user {
        _id
        firstName
        lastName
      }
      title
      createdAt
      details {
        name
        value
      }
    }
    FAQ {
      question
      answer
    }
    enterprise {
      _id
      name
      image
      sector
      country {
        ...CountryFragment
      }
      active
      createdAt
      updatedAt
    }
    type
    active
    createdAt
    updatedAt
    abbreviation
    version
    requiresSignature
    signatureRoles {
      _id
      firmType
      roles
    }
  }
  ${USER_FRAGMENT}
  ${COUNTRY_FRAGMENT}
`;

export const LOG_DETAIL_FRAGMENT = gql`
  fragment LogDetailFragment on LogDetails {
    name
    value
    _id
  }
`;

export const LOG_FRAGMENT = gql`
  fragment LogFragment on Log {
    title
    user {
      ...UserFragment
    }
    details {
      ...LogDetailFragment
    }
    type
    active
    _id
    createdAt
    updatedAt
  }
  ${USER_FRAGMENT}
  ${LOG_DETAIL_FRAGMENT}
`;

export const DOCUMENT_SENT_FRAGMENT = gql`
  fragment DocumentSentFragment on DocumentSent {
    _id
    active
    clients {
      ...UserFragment
    }
    createdAt
    document {
      ...DocumentFragment
    }
    enterpriseCategory {
      _id
      name
    }
    formValues
    history {
      ...LogFragment
    }
    instantJson {
      _id
      active
      json
    }
    roles {
      _id
      name
      user
      status
      previousId
    }
    status
    type
    updatedAt
    userSending {
      ...UserFragment
    }
    enterpriseSending {
      _id
      name
    }
  }
  ${USER_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${LOG_FRAGMENT}
`;

export const QUESTIONNAIRE_FRAGMENT = gql`
  fragment QuestionnaireFragment on Questionnaire {
    _id
    title
    description
    questions {
      _id
      name
      tagName
      question
      placeholder
      type
      options
      precreatedOptions
      isOptional
      active
    }
    country {
      _id
      name
    }
    active
    createdAt
    updatedAt
  }
`;

export const VARIABLE_FRAGMENT = gql`
  fragment VariableFragment on Variable {
    _id
    name
    tagName
    question
    placeholder
    type
    options
    precreatedOptions
    isOptional
    active
  }
`;

export const GUIDE_FRAGMENT = gql`
  fragment GuideFragment on Guide {
    _id
    name
    type
    category {
      ...CategoryFragment
    }
    details {
      name
      value
      _id
    }
    country {
      ...CountryFragment
    }
    description
    createdAt
    updatedAt
    active
  }
  ${CATEGORY_FRAGMENT}
  ${COUNTRY_FRAGMENT}
`;

export const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    _id
    code
    user {
      ...UserFragment
    }
    document {
      _id
      name
      price
      user {
        ...UserFragment
      }
      template
    }
    variableValues {
      value
    }
    status
    history {
      user {
        firstName
        lastName
      }
      title
      createdAt
      updatedAt
      details {
        name
        value
      }
    }
    modality
    promotions {
      name
    }
    active
    createdAt
    updatedAt
  }
  ${USER_FRAGMENT}
`;

export const PAYMENT_FRAGMENT = gql`
  fragment PaymentFragment on Payment {
    reference
    method {
      _id
      name
      commission
      currency {
        _id
        name
        symbol
        rate
        active
      }
    }
    order {
      ...OrderFragment
    }
    amount
    status
    details {
      name
      value
      _id
    }
    active
    _id
    createdAt
    updatedAt
  }
  ${ORDER_FRAGMENT}
`;

export const FINAL_PDF_FRAGMENT = gql`
  fragment FinalPdfFragment on FinalPdf {
    valueTemplate
  }
`;

export const APPOINTMENT_FRAGMENT = gql`
  fragment AppointmentFragment on Appointment {
    _id
    createdAt
    updatedAt
    active
    name
    duration
    price
    enterprise {
      ...EnterpriseFragment
    }
    country {
      ...CountryFragment
    }
    duration
  }
  ${ENTERPRISE_FRAGMENT}
  ${COUNTRY_FRAGMENT}
`;

export const APPOINTMENT_ORDER_FRAGMENT = gql`
  fragment AppointmentOrderFragment on AppointmentOrder {
    _id
    createdAt
    active
    updatedAt
    appointment {
      ...AppointmentFragment
    }
    client {
      ...UserFragment
    }
    lawyer {
      ...UserFragment
    }
    docOrders {
      ...OrderFragment
    }
    documents {
      ...DocumentFragment
    }
    promotions {
      ...PromotionFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
  ${USER_FRAGMENT}
  ${ORDER_FRAGMENT}
  ${PROMOTION_FRAGMENT}
`;

export const SHOP_CART_FRAGMENT = gql`
  fragment ShopCartFragment on ShopCart {
    _id
    createdAt
    updatedAt
    active
    client {
      ...UserFragment
    }
    orders {
      ...OrderFragment
    }
    appOrders {
      ...AppointmentOrderFragment
    }
  }
  ${USER_FRAGMENT}
  ${ORDER_FRAGMENT}
  ${APPOINTMENT_ORDER_FRAGMENT}
`;
