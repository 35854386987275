import React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { User } from '../models';
import Loading from '../components/common/Loading';
import { CURRENT_USER } from '../graphql/queries';

interface IUserContext {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

export const UserContext = React.createContext<IUserContext>({
  user: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUser: () => {},
});

interface UserContextProviderProps {
  children: React.ReactNode;
}

export function UserContextProvider({ children }: UserContextProviderProps) {
  const [user, setUser] = React.useState<User>(null);
  const router = useRouter();
  const {
    loading: loadingUser,
    data: dataUser,
    error: errorUser,
  } = useQuery<{
    currentUser: { user: User };
  }>(CURRENT_USER);

  React.useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      setUser(JSON.parse(localStorage.getItem('user')) ?? null);
    }
  }, []);

  React.useEffect(() => {
    if (!loadingUser) {
      if (dataUser?.currentUser?.user) {
        setUser(dataUser.currentUser?.user);
      } else if (errorUser || !dataUser?.currentUser?.user) {
        setUser(JSON.parse(localStorage.getItem('user')) ?? null);
        if (!user) router.push('/');
      }
    }
  }, [dataUser, errorUser, loadingUser]);

  const context = React.useMemo(() => ({ user, setUser }), [user, setUser]);
  return (
    <UserContext.Provider value={context}>
      {loadingUser ? (
        <div className="w-full h-screen flex items-center justify-center">
          <Loading loading={loadingUser} />
        </div>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
}
