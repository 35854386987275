/* eslint-disable import/no-cycle */
import React from 'react';
import { useRouter } from 'next/router';
import { Country, Enterprise } from '../models';
import { useUser } from '../hooks';

interface IEnterpriseContext {
  currentEnterprise: Enterprise;
  setCurrentEnterprise: React.Dispatch<React.SetStateAction<Enterprise>>;
}

export const EnterpriseContext = React.createContext<IEnterpriseContext>({
  currentEnterprise: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentEnterprise: () => {},
});

interface EnterpriseContextProviderProps {
  children: React.ReactNode;
}

export function EnterpriseContextProvider({
  children,
}: EnterpriseContextProviderProps) {
  const [user] = useUser();
  const defaultEnterprise = {
    name: 'Sin empresa',
    _id: '',
    country: {
      _id: '',
      name: '',
    } as Country,
    sector: '',
    active: false,
  } as Enterprise;
  const [currentEnterprise, setCurrentEnterprise] =
    React.useState<Enterprise>(defaultEnterprise);
  const context = React.useMemo(
    () => ({ currentEnterprise, setCurrentEnterprise }),
    [currentEnterprise, setCurrentEnterprise]
  );

  React.useEffect(() => {
    if (
      user?.enterprise &&
      user?.permissions?.map((p) => p.key).includes('enterpriseUser')
    ) {
      setCurrentEnterprise(user?.enterprise);
    } else {
      setCurrentEnterprise(undefined);
    }
  }, [user]);

  return (
    <EnterpriseContext.Provider value={context}>
      {children}
    </EnterpriseContext.Provider>
  );
}
