import { gql } from '@apollo/client';
import {
  USER_FRAGMENT,
  CATEGORY_FRAGMENT,
  TAX_FRAGMENT,
  CURRENCY_FRAGMENT,
  PAYMENT_METHOD_FRAGMENT,
  FIRM_FRAGMENT,
  COUNTRY_FRAGMENT,
  LAWYER_FRAGMENT,
  CLIENT_FRAGMENT,
  PERMISSION_FRAGMENT,
  DOCUMENT_FRAGMENT,
  VARIABLE_FRAGMENT,
  QUESTIONNAIRE_FRAGMENT,
  ORDER_FRAGMENT,
  PROMOTION_FRAGMENT,
  GUIDE_FRAGMENT,
  PAYMENT_FRAGMENT,
  FINAL_PDF_FRAGMENT,
  APPOINTMENT_FRAGMENT,
  APPOINTMENT_ORDER_FRAGMENT,
  SHOP_CART_FRAGMENT,
  ENTERPRISE_FRAGMENT,
  PLAN_FRAGMENT,
  SUBSCRIPTION_PAYMENT_FRAGMENT,
} from './fragments';

export const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_USERS = gql`
  query GET_USERS(
    $filter: FilterFindManyUserInput
    $sort: SortFindManyUserInput
  ) {
    users(filter: $filter, sort: $sort, limit: 10000) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_USER = gql`
  query GET_USER($filter: FilterFindOneUserInput!) {
    user(filter: $filter) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_USERS_LIST = gql`
  query GET_USERS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyUserInput
  ) {
    userPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...UserFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${USER_FRAGMENT}
`;

// Categories
export const GET_CATEGORIES = gql`
  query GET_CATEGORIES(
    $filter: FilterFindManyCategoryInput
    $sort: SortFindManyCategoryInput
  ) {
    categories(filter: $filter, sort: $sort) {
      ...CategoryFragment
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const GET_PAYMENT_BY_ORDER = gql`
  query GET_PAYMENT_BY_ORDER($data: GetPaymentByOrderInput!) {
    getPaymentByOrder(data: $data) {
      ...PaymentFragment
    }
  }
  ${PAYMENT_FRAGMENT}
`;

export const GET_CATEGORY = gql`
  query GET_CATEGORY($filter: FilterFindOneCategoryInput!) {
    category(filter: $filter) {
      ...CategoryFragment
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const GET_CATEGORIES_LIST = gql`
  query GET_CATEGORIES_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyCategoryInput
  ) {
    categoryPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...CategoryFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${CATEGORY_FRAGMENT}
`;

// TAXES
export const GET_TAXES_LIST = gql`
  query GET_TAXES_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyTaxInput
  ) {
    taxPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...TaxFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${TAX_FRAGMENT}
`;

export const GET_TAX = gql`
  query GET_TAX($filter: FilterFindOneTaxInput!) {
    tax(filter: $filter) {
      ...TaxFragment
    }
  }
  ${TAX_FRAGMENT}
`;

export const GET_TAXES = gql`
  query GET_TAXES(
    $filter: FilterFindManyTaxInput
    $sort: SortFindManyTaxInput
  ) {
    taxes(filter: $filter, sort: $sort) {
      ...TaxFragment
    }
  }
  ${TAX_FRAGMENT}
`;

// CURRENCY
export const GET_CURRENCY = gql`
  query GET_CURRENCY($filter: FilterFindOneCurrencyInput) {
    currency(filter: $filter) {
      ...CurrencyFragment
    }
  }
  ${CURRENCY_FRAGMENT}
`;

export const GET_CURRENCIES = gql`
  query GET_CURRENCIES(
    $filter: FilterFindManyCurrencyInput
    $sort: SortFindManyCurrencyInput
  ) {
    currencies(filter: $filter, sort: $sort) {
      ...CurrencyFragment
    }
  }
  ${CURRENCY_FRAGMENT}
`;

export const GET_CURRENCIES_LIST = gql`
  query GET_CURRENCIES_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyCurrencyInput
  ) {
    currencyPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...CurrencyFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${CURRENCY_FRAGMENT}
`;

// PAYMENT METHODS
export const GET_PAYMENT_METHODS_LIST = gql`
  query GET_PAYMENT_METHODS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyTaxInput
  ) {
    paymentMethodPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...PaymentMethodFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${PAYMENT_METHOD_FRAGMENT}
`;

export const GET_PAYMENT_METHOD = gql`
  query GET_PAYMENT_METHOD($filter: FilterFindOnePaymentMethodInput!) {
    paymentMethod(filter: $filter) {
      ...PaymentMethodFragment
    }
  }
  ${PAYMENT_METHOD_FRAGMENT}
`;

export const GET_PAYMENT_METHODS = gql`
  query GET_PAYMENT_METHODS(
    $filter: FilterFindManyPaymentMethodInput
    $sort: SortFindManyPaymentMethodInput
  ) {
    paymentMethods(filter: $filter, sort: $sort) {
      ...PaymentMethodFragment
    }
  }
  ${PAYMENT_METHOD_FRAGMENT}
`;

export const GET_FIRMS = gql`
  query GET_FIRMS(
    $filter: FilterFindManyFirmInput
    $sort: SortFindManyFirmInput
  ) {
    firms(filter: $filter, sort: $sort) {
      ...FirmFragment
    }
  }
  ${FIRM_FRAGMENT}
`;

export const GET_FIRM = gql`
  query GET_FIRM($filter: FilterFindOneFirmInput!) {
    firm(filter: $filter) {
      ...FirmFragment
    }
  }
  ${FIRM_FRAGMENT}
`;

export const GET_FIRMS_LIST = gql`
  query GET_FIRMS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyFirmInput
  ) {
    firmPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...FirmFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FIRM_FRAGMENT}
`;

// Country
export const GET_COUNTRIES = gql`
  query GET_COUNTRIES(
    $filter: FilterFindManyCountryInput
    $sort: SortFindManyCountryInput
  ) {
    countries(filter: $filter, sort: $sort) {
      ...CountryFragment
    }
  }
  ${COUNTRY_FRAGMENT}
`;

export const GET_COUNTRY = gql`
  query GET_COUNTRY($filter: FilterFindOneCountryInput!) {
    country(filter: $filter) {
      ...CountryFragment
    }
  }
  ${COUNTRY_FRAGMENT}
`;

// Custom
export const GET_COUNTRY_WITH_FIRMS = gql`
  query GET_COUNTRY_WITH_FIRMS($data: GetCountryByIdInput!) {
    countryWithFirms(data: $data) {
      country {
        ...CountryFragment
      }
      firms {
        ...FirmFragment
      }
    }
  }
  ${COUNTRY_FRAGMENT}
  ${FIRM_FRAGMENT}
`;

export const GET_DOCUMENTS_BY_CATEGORY = gql`
  query GET_DOCUMENTS_BY_CATEGORY($data: GetCategoryByIdInput!) {
    getCategoryWithDocuments(data: $data) {
      category {
        ...CategoryFragment
      }
      documents {
        ...DocumentFragment
      }
    }
  }
  ${CATEGORY_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
`;

export const GET_DOCUMENTS_BY_QUESTIONNAIRE = gql`
  query GET_DOCUMENTS_BY_QUESTIONNAIRE(
    $data: GetDocumentsByQuestionnaireInput!
  ) {
    getDocumentsByQuestionnaire(data: $data) {
      ...DocumentFragment
    }
  }
  ${DOCUMENT_FRAGMENT}
`;
export const GET_DOCUMENTS_BY_USER = gql`
  query GET_DOCUMENTS_BY_USER($data: GetDocumentsByUserInput!) {
    getDocumentsByUser(data: $data) {
      ...DocumentFragment
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const GET_USERS_BY_FIRM = gql`
  query GET_USERS_BY_FIRM($data: GetUsersByFirmInput!) {
    getUsersByFirm(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_COUNTRIES_LIST = gql`
  query GET_COUNTRIES_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyCountryInput
  ) {
    countryPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...CountryFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${COUNTRY_FRAGMENT}
`;

// Lawyers
export const GET_LAWYERS = gql`
  query GET_LAWYERS {
    lawyers {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_LAWYER = gql`
  query GET_LAWYER($filter: FilterFindOneLawyerInput!) {
    lawyer(filter: $filter) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_LAWYERS_LIST = gql`
  query GET_LAWYERS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyLawyerInput
  ) {
    lawyerPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...LawyerFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${LAWYER_FRAGMENT}
`;

// Clients
export const GET_CLIENTS = gql`
  query GET_CLIENTS {
    clients {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_CLIENT = gql`
  query GET_CLIENT($filter: FilterFindOneClientInput!) {
    client(filter: $filter) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_CLIENTS_LIST = gql`
  query GET_CLIENTS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyClientInput
  ) {
    clientPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...ClientFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${CLIENT_FRAGMENT}
`;

// Permissions
export const GET_PERMISSIONS = gql`
  query GET_PERMISSIONS(
    $filter: FilterFindManyPermissionInput
    $sort: SortFindManyPermissionInput
  ) {
    permissions(filter: $filter, sort: $sort) {
      ...PermissionFragment
    }
  }
  ${PERMISSION_FRAGMENT}
`;

// Documents
export const GET_DOCUMENT = gql`
  query GET_DOCUMENT($filter: FilterFindOneDocumentInput!) {
    document(filter: $filter) {
      ...DocumentFragment
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const GET_DOCUMENTS = gql`
  query GET_DOCUMENTS(
    $filter: FilterFindManyDocumentInput
    $sort: SortFindManyDocumentInput
  ) {
    documents(filter: $filter, sort: $sort, limit: 2000) {
      ...DocumentFragment
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const GET_DOCUMENTS_LIST = gql`
  query GET_DOCUMENTS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyDocumentInput
  ) {
    documentPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...DocumentFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

// Questionnaires
export const GET_QUESTIONNAIRE = gql`
  query GET_QUESTIONNAIRE($filter: FilterFindOneQuestionnaireInput!) {
    questionnaire(filter: $filter) {
      ...QuestionnaireFragment
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

export const GET_QUESTIONNAIRES = gql`
  query GET_QUESTIONNAIRES(
    $filter: FilterFindManyQuestionnaireInput
    $sort: SortFindManyQuestionnaireInput
  ) {
    questionnaires(filter: $filter, sort: $sort, limit: 2000) {
      ...QuestionnaireFragment
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

export const GET_QUESTIONNAIRES_LIST = gql`
  query GET_QUESTIONNAIRES_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyQuestionnaireInput
  ) {
    questionnairePagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...QuestionnaireFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;
// Variables
export const GET_VARIABLES = gql`
  query GET_VARIABLES(
    $filter: FilterFindManyVariableInput
    $sort: SortFindManyVariableInput
  ) {
    variables(filter: $filter, sort: $sort, limit: 2000) {
      ...VariableFragment
    }
  }
  ${VARIABLE_FRAGMENT}
`;

export const GET_VARIABLE = gql`
  query GET_VARIABLE($filter: FilterFindOneVariableInput!) {
    variable(filter: $filter) {
      ...VariableFragment
    }
  }
  ${VARIABLE_FRAGMENT}
`;

export const GET_VARIABLES_LIST = gql`
  query GET_VARIABLES_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyVariableInput
  ) {
    variablePagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...VariableFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${VARIABLE_FRAGMENT}
`;

export const GET_DOCUMENTS_DATA = gql`
  query GET_DOCUMENTS_DATA(
    $filter: FilterFindOneDocumentInput!
    $filterFirms: FilterFindManyFirmInput
    $filterCategories: FilterFindManyCategoryInput
    $filterQuestionnaires: FilterFindManyQuestionnaireInput
  ) {
    document(filter: $filter) {
      ...DocumentFragment
    }
    permissions {
      ...PermissionFragment
    }
    categories(filter: $filterCategories) {
      ...CategoryFragment
    }
    taxes {
      ...TaxFragment
    }
    firms(filter: $filterFirms) {
      ...FirmFragment
    }
    variables(limit: 2000) {
      ...VariableFragment
    }
    questionnaires(limit: 2000, filter: $filterQuestionnaires) {
      ...QuestionnaireFragment
    }
    countries {
      ...CountryFragment
    }
    enterprises {
      ...EnterpriseFragment
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${CATEGORY_FRAGMENT}
  ${TAX_FRAGMENT}
  ${FIRM_FRAGMENT}
  ${USER_FRAGMENT}
  ${VARIABLE_FRAGMENT}
  ${QUESTIONNAIRE_FRAGMENT}
  ${PERMISSION_FRAGMENT}
  ${COUNTRY_FRAGMENT}
  ${ENTERPRISE_FRAGMENT}
`;
export const GET_CREATE_DOCUMENTS_DATA = gql`
  query GET_CREATE_DOCUMENTS_DATA(
    $filterFirms: FilterFindManyFirmInput
    $filterCategories: FilterFindManyCategoryInput
    $filterQuestionnaires: FilterFindManyQuestionnaireInput
  ) {
    permissions {
      ...PermissionFragment
    }
    categories(filter: $filterCategories) {
      ...CategoryFragment
    }
    taxes {
      ...TaxFragment
    }
    firms(filter: $filterFirms) {
      ...FirmFragment
    }
    variables(limit: 2000) {
      ...VariableFragment
    }
    questionnaires(limit: 2000, filter: $filterQuestionnaires) {
      ...QuestionnaireFragment
    }
    countries {
      ...CountryFragment
    }
    enterprises {
      ...EnterpriseFragment
    }
  }
  ${CATEGORY_FRAGMENT}
  ${TAX_FRAGMENT}
  ${FIRM_FRAGMENT}
  ${VARIABLE_FRAGMENT}
  ${QUESTIONNAIRE_FRAGMENT}
  ${PERMISSION_FRAGMENT}
  ${COUNTRY_FRAGMENT}
  ${ENTERPRISE_FRAGMENT}
`;

// Orders
export const GET_ORDER = gql`
  query GET_ORDER($filter: FilterFindOneOrderInput!) {
    order(filter: $filter) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const GET_ORDERS = gql`
  query GET_ORDERS(
    $filter: FilterFindManyOrderInput
    $sort: SortFindManyOrderInput
  ) {
    orders(filter: $filter, sort: $sort) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

// Promotions
export const GET_PROMOTION = gql`
  query GET_PROMOTION($filter: FilterFindOnePromotionInput!) {
    promotion(filter: $filter) {
      ...PromotionFragment
    }
  }
  ${PROMOTION_FRAGMENT}
`;

export const GET_PROMOTIONS = gql`
  query GET_PROMOTIONS(
    $filter: FilterFindManyPromotionInput
    $sort: SortFindManyPromotionInput
  ) {
    promotions(filter: $filter, sort: $sort) {
      ...PromotionFragment
    }
  }
  ${PROMOTION_FRAGMENT}
`;

// Guide
export const GET_GUIDE = gql`
  query GET_GUIDE($filter: FilterFindOneGuideInput!) {
    guide(filter: $filter) {
      ...GuideFragment
    }
  }
  ${GUIDE_FRAGMENT}
`;

export const GET_GUIDES = gql`
  query GET_GUIDES(
    $filter: FilterFindManyGuideInput
    $sort: SortFindManyGuideInput
  ) {
    guides(filter: $filter, sort: $sort) {
      ...GuideFragment
    }
  }
  ${GUIDE_FRAGMENT}
`;

// Payments
export const GET_PAYMENT = gql`
  query GET_PAYMENT($filter: FilterFindOnePaymentInput!) {
    payment(filter: $filter) {
      ...PaymentFragment
    }
  }
  ${PAYMENT_FRAGMENT}
`;

export const GET_PAYMENTS = gql`
  query GET_PAYMENTS(
    $filter: FilterFindManyPaymentInput
    $sort: SortFindManyPaymentInput
  ) {
    payments(filter: $filter, sort: $sort, limit: 10000) {
      ...PaymentFragment
    }
  }
  ${PAYMENT_FRAGMENT}
`;

export const GET_FINAL_PDF = gql`
  query GET_FINAL_PDF($data: GetFinalPdfInput) {
    getFinalPdf(data: $data) {
      ...FinalPdfFragment
    }
  }
  ${FINAL_PDF_FRAGMENT}
`;

// Dashboard
export const GET_DAILY_OVERVIEW = gql`
  query GET_DAILY_OVERVIEW($data: DailyOverviewFilterInput) {
    getDailyOverview(data: $data) {
      stats {
        totalOrders
        totalEarnings
        totalClients
        recolectedTaxes
        totalPayments
      }
      featuredCategories {
        category {
          ...CategoryFragment
        }
        sold
      }
      segmentedEarnings {
        key
        value
      }
      mostOrderedDocuments {
        document {
          ...DocumentFragment
        }
        sold
      }
    }
  }
  ${CATEGORY_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
`;
export const GET_SELLINGS = gql`
  query GET_SELLINGS($data: SellingsFilterInput) {
    getSellings(data: $data) {
      stats {
        totalOrders
        totalEarnings
        totalClients
        recolectedTaxes
        totalPayments
      }
      segmentedEarnings {
        key
        value
      }
      topSellers {
        categories {
          category {
            ...CategoryFragment
          }
          sold
        }
        documents {
          document {
            ...DocumentFragment
          }
          sold
        }
      }
    }
  }
  ${CATEGORY_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
`;

// Appointments
export const GET_APPOINTMENT = gql`
  query GET_APPOINTMENT($filter: FilterFindOneAppointmentInput!) {
    appointment(filter: $filter) {
      ...AppointmentFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const GET_APPOINTMENTS = gql`
  query GET_APPOINTMENTS(
    $filter: FilterFindManyAppointmentInput
    $sort: SortFindManyAppointmentInput
  ) {
    appointments(filter: $filter, sort: $sort) {
      ...AppointmentFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const GET_APPOINTMENT_ORDER = gql`
  query GET_APPOINTMENT_ORDER($filter: FilterFindOneAppointmentOrderInput!) {
    appointmentOrder(filter: $filter) {
      ...AppointmentOrderFragment
    }
  }
  ${APPOINTMENT_ORDER_FRAGMENT}
`;

export const GET_APPOINTMENT_ORDERS = gql`
  query GET_APPOINTMENT_ORDERS(
    $filter: FilterFindManyAppointmentOrderInput
    $sort: SortFindManyAppointmentOrderInput
  ) {
    appointmentOrders(filter: $filter, sort: $sort) {
      ...AppointmentOrderFragment
    }
  }
  ${APPOINTMENT_ORDER_FRAGMENT}
`;

export const GET_SHOP_CART = gql`
  query GET_SHOP_CART($filter: FilterFindOneShopCartInput!) {
    shopCart(filter: $filter) {
      ...ShopCartFragment
    }
  }
  ${SHOP_CART_FRAGMENT}
`;

export const GET_SHOP_CARTS = gql`
  query GET_SHOP_CARTS(
    $filter: FilterFindManyShopCartInput
    $sort: SortFindManyShopCartInput
  ) {
    shopCarts(filter: $filter, sort: $sort) {
      ...ShopCartFragment
    }
  }
  ${SHOP_CART_FRAGMENT}
`;

export const GET_ENTERPRISE = gql`
  query GET_ENTERPRISE($filter: FilterFindOneEnterpriseInput!) {
    enterprise(filter: $filter) {
      ...EnterpriseFragment
    }
  }
  ${ENTERPRISE_FRAGMENT}
`;

export const GET_ENTERPRISES = gql`
  query GET_ENTERPRISES(
    $filter: FilterFindManyEnterpriseInput
    $sort: SortFindManyEnterpriseInput
  ) {
    enterprises(filter: $filter, sort: $sort) {
      ...EnterpriseFragment
    }
  }
  ${ENTERPRISE_FRAGMENT}
`;

export const GET_CORPORATIVE_USERS = gql`
  query GET_CORPORATIVE_USERS($data: GetCorporativeUsersInput!) {
    getCorporativeUsers(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_ENTERPRISE_LAWYERS = gql`
  query GET_ENTERPRISE_LAWYERS($data: LawyersType!) {
    getEnterpriseLawyers(data: $data) {
      items {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_PLANS = gql`
  query GET_PLANS($filter: FilterFindManyPlanInput) {
    plans(filter: $filter) {
      ...PlanFragment
    }
  }
  ${PLAN_FRAGMENT}
`;

export const GET_SUBSCRIPTION_PAYMENT = gql`
  query GET_SUBSCRIPTION_PAYMENT(
    $filter: FilterFindOneSubscriptionPaymentInput
  ) {
    subscriptionPayment(filter: $filter) {
      ...SubscriptionPaymentFragment
    }
  }
  ${SUBSCRIPTION_PAYMENT_FRAGMENT}
`;

export const GET_SUBSCRIPTION_PAYMENTS = gql`
  query GET_SUBSCRIPTION_PAYMENTS(
    $filter: FilterFindManySubscriptionPaymentInput
  ) {
    subscriptionPayments(filter: $filter) {
      ...SubscriptionPaymentFragment
    }
  }
  ${SUBSCRIPTION_PAYMENT_FRAGMENT}
`;
export const GET_INTERACTIONS_BY_DATE = gql`
  query GET_INTERACTIONS_BY_DATE($data: GetInteractionType!) {
    getInteractionsByDate(data: $data) {
      interactions {
        day
        totalInteractions
        interactions {
          count
          interaction
        }
      }
    }
  }
`;

export const GET_INTERACTIONS_DETAIL = gql`
  query GET_INTERACTIONS_DETAIL($data: GetInteractionType!) {
    getInteractionDetail(data: $data) {
      interactions {
        count
        interaction
      }
    }
  }
`;
