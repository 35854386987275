/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_PROMOTIONS } from '../graphql/queries';

const usePromotions = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [promotions, setPromotions] = React.useState<any[]>([]);

  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useQuery<{ promotions: any[] }>(GET_PROMOTIONS, {
    onCompleted: (data) => {
      setPromotions(data.promotions);
      setLoading(false);
    },
    onError: (error) => {
      setError(error);
      setLoading(false);
    },
  });

  React.useEffect(() => {
    if (data) {
      setPromotions(data.promotions);
    }
  }, [data]);

  return {
    promotions,
    loading: queryLoading || loading,
    error: error || queryError,
  };
};

export default usePromotions;
