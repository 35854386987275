import React from 'react';

function Loading({ loading }: { loading: boolean }) {
  return (
    loading && (
      <div className="w-full h-full flex flex-col flex-wrap justify-center items-center content-center">
        <div className="w-36 h-36">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 100 100"
            xmlSpace="preserve"
            className="fill-current text-primary-300"
          >
            <circle cx="6" cy="50" r="6">
              <animate
                attributeName="opacity"
                begin="0.1"
                dur="1s"
                repeatCount="indefinite"
                values="0;1;0"
              />
            </circle>
            <circle cx="26" cy="50" r="6">
              <animate
                attributeName="opacity"
                begin="0.2"
                dur="1s"
                repeatCount="indefinite"
                values="0;1;0"
              />
            </circle>
            <circle cx="46" cy="50" r="6">
              <animate
                attributeName="opacity"
                begin="0.3"
                dur="1s"
                repeatCount="indefinite"
                values="0;1;0"
              />
            </circle>
          </svg>
        </div>
      </div>
    )
  );
}

export default Loading;
