/* eslint-disable import/no-cycle */
import React from 'react';
import { useRouter } from 'next/router';
import { Country } from '../models';
import { useUser } from '../hooks';

interface ICountryContext {
  currentCountry: Country;
  setCurrentCountry: React.Dispatch<React.SetStateAction<Country>>;
}

export const CountryContext = React.createContext<ICountryContext>({
  currentCountry: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentCountry: () => {},
});

interface CountryContextProviderProps {
  children: React.ReactNode;
}

export function CountryContextProvider({
  children,
}: CountryContextProviderProps) {
  const [user] = useUser();
  const defaultCountry = {
    name: 'Todos',
    abbreviation: 'ALL',
  };
  const [currentCountry, setCurrentCountry] =
    React.useState<Country>(defaultCountry);
  const context = React.useMemo(
    () => ({ currentCountry, setCurrentCountry }),
    [currentCountry, setCurrentCountry]
  );

  React.useEffect(() => {
    if (user?.lawyer?.firm?.country) {
      setCurrentCountry(user?.lawyer?.firm?.country);
    } else {
      setCurrentCountry(defaultCountry);
    }
  }, [user]);

  return (
    <CountryContext.Provider value={context}>
      {children}
    </CountryContext.Provider>
  );
}
